import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import Collapsible from "react-collapsible"
import { FaBoxOpen } from "react-icons/fa"
import { Helmet } from "react-helmet"
import "../css/blog.css"
import Particle1 from "../components/Particle1"
import SEO from "../components/SEO"

const blog = () => {
  return (
    <Layout>
      <SEO title="Blog" description="This is Blog page, by Arpit Somani" />
      <Particle1></Particle1>
      <h6>s</h6>
      <Helmet>
        <script src="https://kit.fontawesome.com/8b75b7499f.js" />
      </Helmet>
      <div className="b-page">
        <h1>Blog</h1>
      </div>

      <aside className="sidebarrr noSelect">
        <div className="special-bx noSelect">
          <h4>Special Box</h4>
          <Collapsible title="special box" trigger={<FaBoxOpen></FaBoxOpen>}>
            <p className="pp">Current Blogging Platform I am using !</p>
            <h4 className="blog-id">TechPro</h4>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://arpitsomani.blogspot.com/"
            >
              <h3 title="Blogger" className="code-plt b1 noSelect">
                <i className="fab fa-blogger-b"></i>
              </h3>
            </a>
          </Collapsible>
        </div>
      </aside>

      <section className="bl-page noSelect">
        <Link to="/tech_blogpage/">
          <div className="button-66">
            <div class="eff-66"></div>
            <h2>Tech Programming Blog</h2>
          </div>
        </Link>
      </section>
    </Layout>
  )
}

export default blog
